<template>
  <div v-if="error">
    {{ error }}
  </div>
  <div
    v-else
    class="grid grid-cols-1 gap-8 sm:container lg:grid-cols-3 xl:grid-cols-12"
  >
    <div class="lg:col-start-1 lg:col-end-3 xl:col-start-4 xl:col-end-10">
      <div
        v-if="dishProposals.length === 0"
        class="border rounded-sm border-neutral-50 bg-white p-5 text-sm lg:p-8"
      >
        <h3 class="mb-2 text-lg font-medium">
          {{ $t("components.community.allProposalsEmptyStateTitle") }}
        </h3>
        <p class="text-sm text-neutral-600">
          {{ $t("components.community.allProposalsEmptyStateDescription") }}
        </p>
      </div>
      <div v-else class="grid gap-4 lg:gap-8">
        <ProposalRow
          v-for="dishProposal in dishProposals"
          :key="dishProposal.uuid"
          :proposal="dishProposal"
        />
      </div>
    </div>
    <div
      class="relative z-10 px-5 sm:pl-0 lg:col-start-3 lg:col-end-4 xl:col-start-10 xl:col-end-13 xl:pr-0 xl:pl-8"
    >
      <CommunitySideInformation>
        <p class="mb-4 text-xs text-neutral-600">
          {{ $t("components.community.sideInformationAllProposals") }}
        </p>
      </CommunitySideInformation>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import CommunitySideInformation from "@/components/community/CommunitySideInformation";
import ProposalRow from "@/components/community/ProposalRow";

export default {
  components: {
    CommunitySideInformation,
    ProposalRow,
  },

  beforeRouteEnter(_to, _from, next) {
    Promise.all([
      store.dispatch("dishProposalForm/resetForm"),
      store.dispatch("dishProposals/fetchDishProposals"),
      store.dispatch("dish/fetchDishCourses"),
    ]).finally(() => {
      next();
    });
  },

  computed: {
    ...mapGetters("dishProposals", ["dishProposals", "error"]),
  },
};
</script>
